import React, { useState, useEffect } from 'react';
import './Contact.css';
import cover from '../../assets/images/campings.jpeg';
import Header from '../../components/Header/Header.js';
import logo from '../../assets/images/logo.png';

const ContactPage = () => {
    return (
        <div className="full-page-container">
            <Header />
            <div className="cover-container">
                <img src={cover} alt="Cover" className="cover-image" />
                <div className="centered-text">Contactez-nous</div>
            </div>
            <div className="contact-info">
                <div className="image-container">
                    <img src={logo} alt="Profile" />
                </div>
                <div className="details">
                    <h2>Nos moyens de contact :</h2>
                    <p>Par e-mail : <strong>eloetwil85@gmail.com</strong></p>
                    <p>Par téléphone : <strong>06.45.44.45.00</strong></p>
                </div>
            </div>
        </div>
    );
};

export default ContactPage;
