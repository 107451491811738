import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import mobilhomeImage1 from '../../assets/images/mobilhome.jpeg';
import './MobilHomeView.css';
import Header from '../../components/Header/Header.js';
import ImageCard from '../../components/ImageCard/ImageCard.js';
import Modal from '../../components/ModalImage/ModalImage.js';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBed, faCoffee, faPerson, faRuler, faUmbrella, faUtensils } from '@fortawesome/free-solid-svg-icons';

const MobilHomeView = () => {
  const { id } = useParams();
  const [mobilhomeData, setMobilhomeData] = useState(null); // State to store camping data
  const [galleryImages, setGalleryImages] = useState([]); // State to store gallery images

  useEffect(() => {
    const fetchMobilhomeData = () => {
      axios.get(`https://api.eloetwil85.fr/api/mobilhomes/${id}`)
        .then(response => {
          setMobilhomeData(response.data);
        })
        .catch(error => {
          console.error('Error fetching mobilhome data:', error);
        });
    };

    fetchMobilhomeData();
  }, [id]);

  useEffect(() => {
    const fetchGalleryImages = () => {
      axios.get(`https://api.eloetwil85.fr/api/images_mobilhome/${id}`)
        .then(response => {
          setGalleryImages(response.data); // Set gallery images
        })
        .catch(error => {
          console.error('Error fetching gallery images:', error);
        });
    };

    fetchGalleryImages();
  }, [id]);

  // État pour contrôler l'affichage du modal et l'image à afficher
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  // Nouveaux états pour la modal de la galerie
  const [galleryModalOpen, setGalleryModalOpen] = useState(false);
  const [selectedGalleryImage, setSelectedGalleryImage] = useState(null);

  // Nouvelle fonction pour gérer le clic sur le bouton "Afficher toutes les photos"
  const handleOpenGalleryModal = () => {
    setGalleryModalOpen(true);
  };

  // Nouvelle fonction pour gérer le clic sur une image de la galerie dans la modal
  const handleGalleryImageClick = (imageUrl) => {
    setSelectedGalleryImage(imageUrl);
  };

  // Cette fonction est appelée lorsque vous cliquez sur une image
  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl); // Définir l'image sélectionnée
    setModalOpen(true); // Ouvrir le modal
  };

  // Ferme le modal
  const closeModal = () => setModalOpen(false);

  return (
    <div className="full-page-container">

      {mobilhomeData ? (<div>

        <Header />
        <h2 className="gallery-title">{mobilhomeData.name}</h2>
        <div className="gallery">
          {/* Afficher l'image 1 si elle existe */}
          {galleryImages.length > 0 && (
            <ImageCard
              imageUrl={galleryImages[0]}
              onClick={() => handleImageClick(galleryImages[0])}
              className="image-card large"
            />
          )}
          <div className="column">
            {/* Afficher l'image 2 si elle existe */}
            {galleryImages.length > 1 && (
              <ImageCard
                imageUrl={galleryImages[1]}
                className="image-card small"
              />
            )}
            {/* Afficher l'image 3 si elle existe */}
            {galleryImages.length > 2 && (
              <ImageCard
                imageUrl={galleryImages[2]}
                className="image-card small"
              />
            )}
          </div>
          <div className="column">
            {/* Afficher l'image 4 si elle existe */}
            {galleryImages.length > 3 && (
              <ImageCard
                imageUrl={galleryImages[3]}
                className="image-card small"
              />
            )}
            {/* Afficher l'image 5 si elle existe */}
            {galleryImages.length > 4 && (
              <div className="image-card small with-button">
                <img src={galleryImages[4]} alt="" />
                <button className="view-all-button" onClick={handleOpenGalleryModal}>Afficher toutes les photos</button>
              </div>
            )}
          </div>
        </div>
        <div className="camping-details">
          <div className="left-section">
            <h3>{mobilhomeData.numberPersons} personnes avec une superficie de {mobilhomeData.superficie}m2</h3>
            <p className="small-text">Réservation par téléphone ou e-mail</p>
            <p>{mobilhomeData.long_desc}</p>
            <h3>Détails de ce logement</h3>

            <div className="additional-section">
              <div className="left-column">
                <div className="row">
                  {/* Icon with area */}
                  <div className="icon-column">
                    <FontAwesomeIcon icon={faCoffee} />
                  </div>
                  <div className="text-column">
                    <span className={mobilhomeData.terrasse === 0 ? 'text-strikethrough' : ''}>Terrasse</span>
                  </div>
                </div>

                <div className="row">
                  {/* Icon with area */}
                  <div className="icon-column">
                    <FontAwesomeIcon icon={faUmbrella} />
                  </div>
                  <div className="text-column">
                    <span className={mobilhomeData.lavelinge === 0 ? 'text-strikethrough' : ''}>Lave-linge</span>
                  </div>
                </div>

                <div className="row">
                  {/* Icon with area */}
                  <div className="icon-column">
                    <FontAwesomeIcon icon={faUtensils} />
                  </div>
                  <div className="text-column">
                    <span className={mobilhomeData.lavevaisselle === 0 ? 'text-strikethrough' : ''}>Lave-vaisselle</span>
                  </div>
                </div>
              </div>
              <div className="right-column">
                <div className="row">
                  {/* Icon with area */}
                  <div className="icon-column">
                    <FontAwesomeIcon icon={faPerson} />
                  </div>
                  <div className="text-column">
                    <span>{mobilhomeData.numberPersons} personnes</span>
                  </div>
                </div>

                <div className="row">
                  {/* Icon with area */}
                  <div className="icon-column">
                    <FontAwesomeIcon icon={faRuler} />
                  </div>
                  <div className="text-column">
                    <span>{mobilhomeData.superficie}m²</span>
                  </div>
                </div>

                <div className="row">
                  {/* Icon with area */}
                  <div className="icon-column">
                    <FontAwesomeIcon icon={faBed} />
                  </div>
                  <div className="text-column">
                    <span>{mobilhomeData.numberRooms} chambre(s)</span>
                  </div>
                </div>
              </div>
              <p className="additional-text">
                Exclusivité : {mobilhomeData.exclu === "" ? "Pas d'exclusivité" : (mobilhomeData.exclu)}
              </p>
            </div>

          </div>
          <div className="right-section"></div>
          {/*<div className="right-section">
            <h3 className="section-title">Nos mobil-homes</h3>
            <ul>
              {mobilhomeData.mobilehomes.map((mobilehome, index) => (
                <li key={index} className="mobil-home-item">
                  <div>
                    {mobilehome.name}
                  </div>
                  <div>
                    <img src={mobilhomeImage1} style={{width: 50 + '%'}} alt={`Mobilhome ${index + 1}`} />
                  </div>
                </li>
              ))}
            </ul>
              </div>*/}
        </div>

        <Modal isOpen={modalOpen} close={closeModal}>
          <img src={selectedImage} alt="Expanded View" />
        </Modal>
      </div>) : (<div style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div className="loader" />
      </div>)}
    </div>
  );
};

export default MobilHomeView;
