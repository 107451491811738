// ImageCard.js
import React from 'react';
import './ImageCard.css';

const ImageCard = ({ imageUrl, className, onClick}) => {
  return (
    <div className={`image-card ${className}`} onClick={onClick}>
      <img src={imageUrl} alt="Gallery" />
    </div>
  );
};

export default ImageCard;