import React, { useState, useEffect } from 'react';
import './HeaderHome.css';
import { NavLink } from 'react-router-dom';
import logo from '../../assets/images/logo.png';

const HeaderHome = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [scrolled, setScrolled] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      if (windowWidth > 768) {
        setMenuOpen(false);
      }
    };

    const handleScroll = () => {
      if (window.scrollY > 10) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [windowWidth]);

  return (
    <div className={`header ${scrolled ? 'scrolled' : ''}`}>
      <div className="logo">
        <img src={logo} alt="Logo" />
      </div>
      <div className={`navbar ${menuOpen ? 'open' : ''}`}>
        <ul className="centered-items">
          <li><NavLink to="/" activeClassName="active">Accueil</NavLink></li>
          <li><NavLink to="/campings" activeClassName="active">Nos campings</NavLink></li>
          <li><NavLink to="/mobilhomes" activeClassName="active">Nos mobils-homes</NavLink></li>
        </ul>
      </div>
      <div className="contact-section">
        <NavLink to="/contact" activeClassName="active">Contactez-nous</NavLink>
      </div>
      <div className="menu-button" onClick={toggleMenu}>
        <div className={`bar ${menuOpen ? 'open' : ''}`}></div>
        <div className={`bar ${menuOpen ? 'open' : ''}`}></div>
        <div className={`bar ${menuOpen ? 'open' : ''}`}></div>
      </div>
    </div>
  );
};

export default HeaderHome;
