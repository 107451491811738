import React from 'react';
import './Cgu.css'; // Assurez-vous de créer ce fichier CSS pour styliser votre page si nécessaire

const CGUPage = () => {
  return (
    <div className="cgu-container">
      <h1>CONDITIONS GENERALES D'UTILISATION</h1>
      <p><strong>En vigueur au 20/01/2024</strong></p>

      <h2>ARTICLE 1 : LES MENTIONS LEGALES</h2>
      <p>L'édition du site ELOETWIL85.FR est assurée par la Société SARL M.B.W au capital de 4000 euros, immatriculée au RCS de LE MANS  sous le numéro 750355802, dont le siège social est situé au 19 ROUTE DES FOUTAIES 72270 MEZERAY</p>
      <p>Numéro de téléphone : 0645444500</p>
      <p>Adresse e-mail : ELOETWIL85@GMAIL.COM</p>
      <p>Le Directeur de la publication est : PIPELIER WILLY</p>
      <p>Numéro de TVA intracommunautaire : FR58750355802</p>
      <p>L'hébergeur du site ELOETWIL85.FR est la société IONOS., dont le siège social est situé au . 7 PLACE DE LA GARE 57200 SARREGUEMINES.</p>

      <h2>ARTICLE 2 : ACCES AU SITE</h2>
      <p>Le site ELOETWIL85.FR permet à l'Utilisateur un accès gratuit aux services suivants :</p>
      <ul>
        <li>Le site internet propose les services suivants :</li>
        <li>VISUALISATION DES MOBIL-HOMES</li>
        <li>TARIFS</li>
        <li>DISPONIBILITES</li>
      </ul>
      <p>Le site est accessible gratuitement en tout lieu à tout Utilisateur ayant un accès à Internet. Tous les frais supportés par l'Utilisateur pour accéder au service (matériel informatique, logiciels, connexion Internet, etc.) sont à sa charge.</p>

      <h2>ARTICLE 3 : COLLECTE DES DONNEES</h2>
      <p>Le site assure à l'Utilisateur une collecte et un traitement d'informations personnelles dans le respect de la vie privée conformément à la loi n°78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés.</p>
      <p>En vertu de la loi Informatique et Libertés, en date du 6 janvier 1978, l'Utilisateur dispose d'un droit d'accès, de rectification, de suppression et d'opposition de ses données personnelles. L'Utilisateur exerce ce droit via un email de contact.</p>

      {/* Ajoutez les autres articles de la même manière */}

      <h2>ARTICLE 4 : PROPRIETE INTELLECTUELLE</h2>
      <p>Les marques, logos, signes ainsi que tous les contenus du site (textes, images, son…) font l'objet d'une protection par le Code de la propriété intellectuelle et plus particulièrement par le droit d'auteur.</p>
      <p>L'Utilisateur doit solliciter l'autorisation préalable du site pour toute reproduction, publication, copie des différents contenus. Il s'engage à une utilisation des contenus du site dans un cadre strictement privé, toute utilisation à des fins commerciales et publicitaires est strictement interdite.</p>

      {/* Ajoutez les autres articles de la même manière */}

      <h2>ARTICLE 5 : RESPONSABILITE</h2>
      <p>Les sources des informations diffusées sur le site ELOETWIL85.FR sont réputées fiables mais le site ne garantit pas qu’il soit exempt de défauts, d’erreurs ou d’omissions.</p>
      <p>Les informations communiquées sont présentées à titre indicatif et général sans valeur contractuelle. Malgré des mises à jour régulières, le site ELOETWIL85.FR ne peut être tenu responsable de la modification des dispositions administratives et juridiques survenant après la publication. De même, le site ne peut être tenue responsable de l’utilisation et de l’interprétation de l’information contenue dans ce site.</p>
      <p>Le site ELOETWIL85.FR ne peut être tenu pour responsable d’éventuels virus qui pourraient infecter l’ordinateur ou tout matériel informatique de l’Internaute, suite à une utilisation, à l’accès, ou au téléchargement provenant de ce site.</p>
      <p>La responsabilité du site ne peut être engagée en cas de force majeure ou du fait imprévisible et insurmontable d'un tiers.</p>

      {/* Ajoutez les autres articles de la même manière */}

      <h2>ARTICLE 6 : LIENS HYPERTEXTES</h2>
      <p>Des liens hypertextes peuvent être présents sur le site. L’Utilisateur est informé qu’en cliquant sur ces liens, il sortira du site ELOETWIL85.FR. Ce dernier n’a pas de contrôle sur les pages web sur lesquelles aboutissent ces liens et ne saurait, en aucun cas, être responsable de leur contenu.</p>

      {/* Ajoutez les autres articles de la même manière */}

      <h2>ARTICLE 7 : COOKIES</h2>
      <p>L’Utilisateur est informé que lors de ses visites sur le site, un cookie peut s’installer automatiquement sur son logiciel de navigation.</p>
      <p>Les cookies sont de petits fichiers stockés temporairement sur le disque dur de l’ordinateur de l’Utilisateur par votre navigateur et qui sont nécessaires à l’utilisation du site ELOETWIL85.FR. Les cookies ne contiennent pas d’information personnelle et ne peuvent pas être utilisés pour identifier quelqu’un. Un cookie contient un identifiant unique, généré aléatoirement et donc anonyme. Certains cookies expirent à la fin de la visite de l’Utilisateur, d’autres restent.</p>
      <p>L’information contenue dans les cookies est utilisée pour améliorer le site ELOETWIL85.FR. En naviguant sur le site, L’Utilisateur les accepte. L’Utilisateur pourra désactiver ces cookies par l’intermédiaire des paramètres figurant au sein de son logiciel de navigation.</p>

      {/* Ajoutez les autres articles de la même manière */}

      <h2>ARTICLE 8 : DROIT APPLICABLE ET JURIDICTION COMPETENTE</h2>
      <p>La législation française s'applique au présent contrat. En cas d'absence de résolution amiable d'un litige né entre les parties, les tribunaux français seront seuls compétents pour en connaître.</p>
      <p>Pour toute question relative à l’application des présentes CGU, vous pouvez joindre l’éditeur aux coordonnées inscrites à l’ARTICLE 1.</p>
      <p>CGU réalisées sur <a href="http://legalplace.fr/">legalplace.fr</a>.</p>
    </div>
  );
};

export default CGUPage;
