import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/Home/Home.js';
import Footer from './components/Footer/Footer.js';
import CampingPage from './pages/Camping/Camping.js';
import CampingView from './pages/CampingView/CampingView.js';
import MobilHomeView from './pages/MobilHomeView/MobilHomeView.js';
import MobilhomePage from './pages/Mobilhome/Mobilhomes.js';
import ContactPage from './pages/Contact/Contact.js';
import CGUPage from './pages/CGU/Cgu.js';

function App() {
  return (
    <div className="App">
      <Router>
        <div className="page-container">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/campings" element={<CampingPage/>} />
            <Route path='/camping/:id' element={<CampingView/>}/>
            <Route path="/mobilhomes" element={<MobilhomePage/>} />
            <Route path='/mobilhome/:id' element={<MobilHomeView/>}/>
            <Route path='/contact' element={<ContactPage/>}/>
            <Route path='/CGU' element={<CGUPage/>}/>
          </Routes>
        </div>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
