import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import mobilhomeImage1 from '../../assets/images/mobilhome.jpeg';
import './CampingView.css';
import Header from '../../components/Header/Header.js';
import ImageCard from '../../components/ImageCard/ImageCard.js';
import Modal from '../../components/ModalImage/ModalImage.js';
import axios from 'axios';

const CampingView = () => {
  const { id } = useParams();
  const [campingData, setCampingData] = useState(null); // State to store camping data
  const [galleryImages, setGalleryImages] = useState([]); // State to store gallery images

  useEffect(() => {
    const fetchCampingData = () => {
      console.log("Before axios request");
      axios.get(`https://api.eloetwil85.fr/api/campings/${id}`)
        .then(response => {
          console.log("After axios request");
          setCampingData(response.data);
        })
        .catch(error => {
          console.error('Error fetching camping data:', error);
        });
    };
  
    fetchCampingData();
  }, [id]);

  useEffect(() => {
    const fetchGalleryImages = () => {
      axios.get(`https://api.eloetwil85.fr/api/images_camping/${id}`)
        .then(response => {
          setGalleryImages(response.data); // Set gallery images
        })
        .catch(error => {
          console.error('Error fetching gallery images:', error);
        });
    };

    fetchGalleryImages();
  }, [id]);

  // État pour contrôler l'affichage du modal et l'image à afficher
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  // Nouveaux états pour la modal de la galerie
  const [galleryModalOpen, setGalleryModalOpen] = useState(false);
  const [selectedGalleryImage, setSelectedGalleryImage] = useState(null);

  

  // Nouvelle fonction pour gérer le clic sur le bouton "Afficher toutes les photos"
  const handleOpenGalleryModal = () => {
    setGalleryModalOpen(true);
  };

  // Nouvelle fonction pour gérer le clic sur une image de la galerie dans la modal
  const handleGalleryImageClick = (imageUrl) => {
    setSelectedGalleryImage(imageUrl);
  };

  // Cette fonction est appelée lorsque vous cliquez sur une image
  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl); // Définir l'image sélectionnée
    setModalOpen(true); // Ouvrir le modal
  };

  // Ferme le modal
  const closeModal = () => setModalOpen(false);

  return (
    <div className="full-page-container">

      {campingData ? (<div>

      <Header />
      <h2 className="gallery-title">{campingData.name}</h2>
      <div className="gallery">
          {/* Afficher l'image 1 si elle existe */}
          {galleryImages.length > 0 && (
            <ImageCard
              imageUrl={galleryImages[0]}
              onClick={() => handleImageClick(galleryImages[0])}
              className="image-card large"
            />
          )}
          <div className="column">
            {/* Afficher l'image 2 si elle existe */}
            {galleryImages.length > 1 && (
              <ImageCard
                imageUrl={galleryImages[1]}
                className="image-card small"
              />
            )}
            {/* Afficher l'image 3 si elle existe */}
            {galleryImages.length > 2 && (
              <ImageCard
                imageUrl={galleryImages[2]}
                className="image-card small"
              />
            )}
          </div>
          <div className="column">
            {/* Afficher l'image 4 si elle existe */}
            {galleryImages.length > 3 && (
              <ImageCard
                imageUrl={galleryImages[3]}
                className="image-card small"
              />
            )}
            {/* Afficher l'image 5 si elle existe */}
            {galleryImages.length > 4 && (
              <div className="image-card small with-button">
                <img src={galleryImages[4]} alt="" />
                <button className="view-all-button" onClick={handleOpenGalleryModal}>Afficher toutes les photos</button>
              </div>
            )}
          </div>
        </div>
        <div className="camping-details">
        <div className="left-section">
          <h3>{campingData.city} - {campingData.adress}</h3>
          <p className="small-text">{`Tél : ` + campingData.phone + ` / E-mail : ` + campingData.email}</p>
          <p>{campingData.description}</p>
        </div>
        <div className="right-section"></div>
      </div>

      <Modal isOpen={modalOpen} close={closeModal}>
        <img src={selectedImage} alt="Expanded View" />
      </Modal>
      </div>):(<div style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div className="loader"/>
      </div>)}
    </div>
  );
};

export default CampingView;