import React, { useState, useEffect } from 'react';
import './Home.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import HeaderHome from '../../components/HeaderHome/HeaderHome.js';
// Importez les images
import mobilhomeImage1 from '../../assets/images/mobilhome.jpeg';
import cover from '../../assets/images/cover.png';
import axios from 'axios';
import { Link } from 'react-router-dom';

const HomePage = () => {
    const mobilhomes = [
        { photo: mobilhomeImage1, name: 'MobilHome 1', description: 'Description 1' },
        { photo: mobilhomeImage1, name: 'MobilHome 2', description: 'Description 2' },
        { photo: mobilhomeImage1, name: 'MobilHome 3', description: 'Description 3' },
        { photo: mobilhomeImage1, name: 'MobilHome 4', description: 'Description 4' }
    ];

    const [mobilhomesData, setMobilhomesData] = useState([]);

    useEffect(() => {
        // Effectuez la requête HTTP pour récupérer les données des campings
        axios.get('https://api.eloetwil85.fr/api/mobilhomes')
            .then(response => {
                setMobilhomesData(response.data);
                console.log("ok");
            })
            .catch(error => {
                console.error('Erreur de requête', error);
            });
    }, []); 

    const [currentIndex, setCurrentIndex] = useState(0);

    const prevIndex = (currentIndex - 1 + mobilhomes.length) % mobilhomes.length;
    const nextIndex = (currentIndex + 1) % 4;

    const nextMobilHome = () => {
        setCurrentIndex(nextIndex);
    };

    const getCurrentMobilHomes = () => {
        if (mobilhomesData.length === 0) {
            // Si les données ne sont pas encore chargées, renvoyer un tableau vide
            return [];
        }
    
        const prevIndex = (currentIndex - 1 + mobilhomesData.length) % mobilhomesData.length;
        const nextIndex = (currentIndex + 1) % mobilhomesData.length;
    
        return [mobilhomesData[prevIndex], mobilhomesData[currentIndex], mobilhomesData[nextIndex]];
    };    

    const phrases = [
        "Location de mobil-homes.",
        "Destination Vendée.",
        "Plage - pêche - randonnée -vélo.",
        "Voyager léger, tout inclus",
        "Détente assurée"
    ];
    const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
    const [currentText, setCurrentText] = useState("");
    const [isVisible, setIsVisible] = useState(false);

    const autoScrollMobilHomes = () => {
        nextMobilHome();
    };

    useEffect(() => {
        // Utilisez setInterval pour faire défiler automatiquement les mobil-homes toutes les 3 secondes
        const intervalId = setInterval(autoScrollMobilHomes, 3000);
    
        // Nettoyez l'intervalle lorsque le composant est démonté pour éviter les fuites de mémoire
        return () => clearInterval(intervalId);
    }, [currentIndex]); // Ajoutez currentIndex comme dépendance

    useEffect(() => {
        const interval = setInterval(() => {
            if (currentText.length < phrases[currentPhraseIndex].length) {
                setCurrentText(prevText => prevText + phrases[currentPhraseIndex][currentText.length]);
            } else {
                clearInterval(interval);

                if (currentPhraseIndex < phrases.length - 1) {
                    setTimeout(() => {
                        setCurrentText("");
                        setCurrentPhraseIndex(prevIndex => prevIndex + 1);
                    }, 2000);
                } else {
                    setTimeout(() => {
                        setCurrentText("");
                        setCurrentPhraseIndex(0);
                    }, 2000);
                }
            }
        }, 30);

        return () => clearInterval(interval);
    }, [currentText, currentPhraseIndex]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            entries => {
                if (entries[0].isIntersecting) {
                    setIsVisible(true);
                } else {
                    setIsVisible(false);
                }
            },
            {
                threshold: 0.1
            }
        );

        const el = document.querySelector('.title-below-image');
        if (el) {
            observer.observe(el);
        }

        return () => {
            if (el) {
                observer.unobserve(el);
            }
        };
    }, []);

    return (
        <div className="full-page-container">
{mobilhomesData && mobilhomesData.length > 0 ? (<div>

            <div className="homepage-container">
                <HeaderHome />
                <div className="homepage-text">{currentText}</div>
                <div className="scroll-arrow">
                    <FontAwesomeIcon icon={faArrowDown} size="1.2x" />
                </div>
            </div>

            <div className="content-below-image">
                <h1 className={`title-below-image`}>Recherchez votre endroit...</h1>

                <div className="mobilhomes-container">
                    {getCurrentMobilHomes().map((mobilhome, index) => (
                        <div className="mobilhome" key={index}>
                        <div className="mobilhome-image" style={{ backgroundImage: `url(${mobilhome.image})` }}></div>
                        <h2>{mobilhome.name}</h2>
                        <p>{mobilhome.short_desc}</p>
                        <Link to={"/mobilhome/"+mobilhome.id_mobilehome}>
                                Voir plus
                        </Link>                        
                        </div>
                    ))}
                </div>
            </div></div>):(<div style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
    <div className="loader" />
    <div>Chargement en cours</div>
</div>)}
        </div>
    );
};

export default HomePage;
