import React from 'react';
import './Footer.css';
const Footer = () => {
  return (
    <footer className="footer">
        <div className="footer-content">
                    <div className="footer-top">
                        <h2>ELO & WILL</h2>
                        <div className="footer-links">
                            <a href="/CGU">Conditions d'utilisation & CGU</a>
                        </div>
                    </div>
                    <p className="footer-copyright">© 2023 ELO & WILL. Tous droits réservés.</p>
        </div>
    </footer>
  );
};

export default Footer;
