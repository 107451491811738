import React, { useState, useEffect } from 'react';
import './Camping.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapLocation, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import mobilhomeImage1 from '../../assets/images/mobilhome.jpeg';
import cover from '../../assets/images/campings.jpeg';
import Header from '../../components/Header/Header.js';
import axios from 'axios';
import { Link } from 'react-router-dom';

const CampingPage = () => {
    console.log("CampingView component is rendered");

    const [campings, setCampings] = useState([]);

    useEffect(() => {
        // Effectuez la requête HTTP pour récupérer les données des campings
        axios.get('https://api.eloetwil85.fr/api/campings')
            .then(response => {
                console.log(response);
                setCampings(response.data);
            })
            .catch(error => {
                console.error('Erreur de requête', error);
            });
    }, []); 

    return (
        <div className="full-page-container">
            <Header />
            <div className="cover-container">
                <img src={cover} alt="Cover" className="cover-image" />
                <div className="centered-text">Nos campings</div>
            </div>
            <div className="camping-container">
                {campings.map((camping, index) => (
                    <div key={index} className="camping-card">
                        <img src={camping.image} alt={camping.name} className="camping-image" />
                        <div className="camping-info">
                            <div className="camping-name">{camping.name}</div>
                            <div className="camping-description">{camping.description}</div>
                            <div className="camping-location">
                                <FontAwesomeIcon icon={faMapLocation} className="map-icon" />
                                <span>{camping.city}, {camping.adress}</span>
                            </div>
                            <Link to={"/camping/"+camping.id_camping} className="see-more-button">
                                Voir plus
                            </Link>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CampingPage;
