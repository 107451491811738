import React, { useState, useEffect } from 'react';
import './Mobilhomes.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapLocation, faChevronLeft, faChevronRight, faPercent, faPerson } from '@fortawesome/free-solid-svg-icons';
import mobilhomeImage1 from '../../assets/images/mobilhome.jpeg';
import cover from '../../assets/images/campings.jpeg';
import Header from '../../components/Header/Header.js';
import axios from 'axios';
import { Link } from 'react-router-dom';

const MobilhomePage = () => {
    const [mobilhomes, setMobilhomes] = useState([]);

    useEffect(() => {
        // Effectuez la requête HTTP pour récupérer les données des campings
        axios.get('https://api.eloetwil85.fr/api/mobilhomes')
            .then(response => {
                setMobilhomes(response.data);
            })
            .catch(error => {
                console.error('Erreur de requête', error);
            });
    }, []);

    return (
        <div className="full-page-container">
            <Header />
            <div className="cover-container">
                <img src={cover} alt="Cover" className="cover-image" />
                <div className="centered-text">Nos mobils-homes</div>
            </div>
            {mobilhomes && mobilhomes.length > 0 ? (
            <div className="camping-container">
                        {mobilhomes.map((mobilhome, index) => (
                            <div key={index} className="camping-card">
                                <img src={mobilhome.image} alt={mobilhome.name} className="camping-image" />
                                <div className="camping-info">
                                    <div className="camping-name">{mobilhome.name}</div>
                                    <p>petite description</p>

                                    <div className="camping-location">
                                        <FontAwesomeIcon icon={faPerson} className="map-icon" />
                                        <span>Pour {mobilhome.numberPersons} personnes</span>
                                    </div>
                                    <Link to={"/mobilhome/" + mobilhome.id_mobilehome} className="see-more-button">
                                        Voir plus
                                    </Link>
                                </div>
                            </div>
                        ))}
                
            </div>):(<div style={{ height: '50vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div className="loader" />
              </div>)}
        </div>
    );
};

export default MobilhomePage;
